import { ToWords } from 'to-words';
import { DateTime } from 'luxon';
import {
  LOOM_TYPE_REVERSE_MAP,
  ORDER_TYPE,
  QUOTE_CATEGORY,
  UNITS,
  UNITS_REVERSED,
  UNITS_SHORT_NAME,
  UNIT_CONVERSION_FACTORS
} from 'constants/globalConstant';

export const numWithCommas = (val, defaultVal = '') => {
  return val ? val.toString().replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ',') : defaultVal;
};

export const dataSortOrder = (order, field, matchfield) => {
  if (field === matchfield) {
    switch (order) {
      case 'ascend':
        return 'asc';
      case 'descend':
        return 'desc';
      default:
        return '';
    }
  } else {
    return null;
  }
};

export const unitFunction = (incomingUnit, toShowUnit) => {
  switch (incomingUnit) {
    case UNITS.YARDS:
      return 'm';
    case UNITS.POUNDS:
      return 'kg';
    default:
      return toShowUnit;
  }
};

export const ANTD_TABLE_ORDER = {
  asc: 'ascend',
  desc: 'descend'
};

export const gstPattern = (gstNumber) => {
  return `${gstNumber.slice(0, 2)} ${gstNumber.slice(2, 12)} ${gstNumber.slice(12, 15)}`;
};

export function numberToWords(value, localeCode = 'en-IN') {
  const toWords = new ToWords({
    localeCode,
    converterOptions: {
      currency: true,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: false
    }
  });
  return toWords.convert(value);
}

export const formateDateTime = (date) => {
  return DateTime.fromISO(date).toLocaleString(DateTime.DATE_MED);
  // Output -> DATE_MMMDDYYYYY
};

export const formatDate = (date) => {
  return date.format('YYYY-MM-DD HH:mm:ss').toString();
};

export const formatTime = (dateIso) => {
  return DateTime.fromISO(dateIso).toLocaleString(DateTime.TIME_SIMPLE);
  // Output like -> 09:30 AM
};

export const convertToUSD = (amount, conversionRate) => {
  const convertedPrice = (Number(amount) / Number(conversionRate)).toFixed(2);
  return convertedPrice;
};

export const valueChecker = (value, returnValue = '') => value ?? returnValue;

export const getQualitySpecifications = (inputs) => {
  const {
    threadcount,
    construction,
    width,
    weight,
    loomType,
    requirementType,
    denimQualtiySpecification,
    weightUnitId
  } = inputs;
  let qualitySpecifications = '';
  if (requirementType === 6) return denimQualtiySpecification;
  if (threadcount) {
    qualitySpecifications += `${threadcount} / `;
  }

  if (construction) {
    qualitySpecifications += `${construction} / `;
  }

  if (width) {
    qualitySpecifications += `${width} inch / `;
  }

  if (weight) {
    qualitySpecifications += `${weight} ${UNITS_REVERSED[weightUnitId]} / `;
  }

  if (loomType) {
    qualitySpecifications += `${LOOM_TYPE_REVERSE_MAP[loomType]}`;
  }

  return qualitySpecifications;
};

export const windowReload = () => window.location.reload();

export const nounWord = (word, count) => {
  return Number(count) <= 1 ? String(word) : `${word}s`;
};

export const getLink = (finder, arr, keyword = 'title') => {
  return arr
    ?.filter((data) => {
      return data[keyword] === finder;
    })
    .pop();
};

export const getNameFromLink = (linkUrl) => {
  if (linkUrl) {
    const urlSpliter = linkUrl.split('/');
    return urlSpliter[urlSpliter.length - 1];
  }
  return null;
};

export const getLinkLfbModal = (finder, arr, keyword = 'title') => {
  return arr
    ?.filter((data) => {
      return data?.fileDetail?.title === finder;
    })
    .pop();
};

export const getLinkApproveForm = (finder, arr, keyword = 'title') => {
  return arr
    ?.filter((data) => {
      return data?.fileDetails?.title === finder;
    })
    .pop();
};

export const getFile = (files, titleName) => {
  let url = null;
  files.forEach((file) => {
    if (file.fileDetails.title === titleName) {
      url = file.fileDetails.url;
    }
  });
  return url;
};

const removeSpecialCharacter = (value) => value?.trim()?.replace(/[^a-zA-Z ]/g, '');

// check file type for File upload component
export const checkFileType = (uploadedFileType, accetableFileType) => {
  const matchingFileTypes = accetableFileType
    .split(',')
    .filter((fileType) => uploadedFileType.includes(removeSpecialCharacter(fileType)));
  return Boolean(matchingFileTypes.length);
};

export const computedQuantity = (quantity, unit) => {
  if (unit === UNITS.YARDS) {
    return (+quantity * 0.9144).toFixed(2);
  }
  if (unit === UNITS.POUNDS) {
    return (+quantity * 0.453592).toFixed(2);
  }
  return quantity;
};

export const orderTypeToQuoteCategoryMapping = (orderType) => {
  if (!orderType) {
    return 0;
  }
  if (ORDER_TYPE.FINISHED_FABRIC === orderType) {
    return QUOTE_CATEGORY.BUNDLED;
  }
  if (
    [ORDER_TYPE.YARN_DYED_RUNNING_QUALITY, ORDER_TYPE.YARN_DYED_MADE_TO_ORDER].includes(orderType)
  ) {
    return QUOTE_CATEGORY.YARN_DYED;
  }
  if ([ORDER_TYPE.GREIGE_RUNNING_QUALITY, ORDER_TYPE.GREIGE_MADE_TO_ORDER].includes(orderType)) {
    return QUOTE_CATEGORY.GREIGE;
  }
  if ([ORDER_TYPE.DENIM_RUNNING_QUALITY, ORDER_TYPE.DENIM_MADE_TO_ORDER].includes(orderType)) {
    return QUOTE_CATEGORY.DENIM;
  }
  return QUOTE_CATEGORY.JOB_WORK;
};

export const priceTransformer = (orderData) => {
  const quoteObject = orderData?.greigeAndJobWorkQuoteDetails;
  const { shrinkagePercentage } = quoteObject.jobWorkQuoteDetails;
  const greigeSupplierUnitPrice =
    Number(quoteObject.greigeQuoteDetails.fobQuote) +
    Number(quoteObject.greigeQuoteDetails.packagingQuote);
  const jobWorkSupplierUnitPrice =
    Number(quoteObject.jobWorkQuoteDetails.fobQuote) +
    Number(quoteObject.jobWorkQuoteDetails.packagingQuote);
  const totalSupplierUnitPrice =
    greigeSupplierUnitPrice * (1 + Number(shrinkagePercentage) / 100) + jobWorkSupplierUnitPrice;

  return {
    greigeSupplierUnitPrice,
    jobWorkSupplierUnitPrice,
    totalSupplierUnitPrice,
    shrinkagePercentage: Number(shrinkagePercentage)
  };
};

const marginCalculationFromSupplierUnitPrice = (inputs) => {
  const {
    sellingPrice = 0,
    logisticsCost = 0,
    supplierUnitPrice = 0,
    customerSidePriceConversionFactor = 1
  } = inputs;
  return (
    (+sellingPrice * customerSidePriceConversionFactor -
      (+logisticsCost * customerSidePriceConversionFactor + +supplierUnitPrice)) *
    (100 / +supplierUnitPrice)
  ).toFixed(2);
};

export const marginCalculator = (inputs) => {
  const {
    supplierUnitPrice = 0,
    totalSupplierUnitPrice = 0,
    greigeSupplierUnitPrice = 0,
    jobWorkSupplierUnitPrice = 0,
    shrinkagePercentage = 0,
    logisticsCost = 0,
    sellingPrice = 0,
    customerSidePriceConversionFactor = 1,
    orderType
  } = inputs;
  const isJobWorkOrder = orderTypeToQuoteCategoryMapping(orderType) === QUOTE_CATEGORY.JOB_WORK;
  if (isJobWorkOrder) {
    const totalSupplierUnitPriceValue =
      +greigeSupplierUnitPrice * (1 + +shrinkagePercentage / 100) + +jobWorkSupplierUnitPrice;
    return marginCalculationFromSupplierUnitPrice({
      customerSidePriceConversionFactor,
      sellingPrice,
      logisticsCost,
      supplierUnitPrice: totalSupplierUnitPriceValue
    });
  }
  return marginCalculationFromSupplierUnitPrice({
    sellingPrice,
    logisticsCost,
    supplierUnitPrice: supplierUnitPrice || totalSupplierUnitPrice,
    customerSidePriceConversionFactor
  });
};

export const supplierSideUnitConversion = (unit) => {
  if (unit === UNITS_SHORT_NAME.YARDS) {
    return UNITS_SHORT_NAME.METERS;
  }
  if (unit === UNITS_SHORT_NAME.POUNDS) {
    return UNITS_SHORT_NAME.KILOGRAMS;
  }
  return unit;
};

export const baseUnitConversion = (unit) => {
  if (unit === UNITS_SHORT_NAME.YARDS) {
    return UNIT_CONVERSION_FACTORS.YARD_TO_METER;
  }
  if (unit === UNITS_SHORT_NAME.POUNDS) {
    return UNIT_CONVERSION_FACTORS.POUND_TO_KILOGRAM;
  }
  return 1;
};

export const getArrayOfValuesOnOrder = (desiredOrder = [], objMapping = {}) =>
  desiredOrder.map((key) => objMapping[key]);

export const handleFileName = (fileName, lenReq) => {
  let newFileName = '';
  const arr = fileName.split('.');
  const joined = arr.slice(0, arr.length - 1).join('');
  if (joined.length > lenReq) {
    newFileName += joined.substr(0, lenReq);
  } else newFileName += joined;
  newFileName = `${newFileName}..${arr[arr.length - 1]}`;
  return newFileName;
};

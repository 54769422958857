import { combineReducers } from 'redux';
import dateRangeFilterReducer from 'redux/slices/dateRangeFilter';
import orderConfirmationReducer from './orderConfirmation';
import supplierPaymentReducer from './supplierPayment';
import customerPaymentReducer from './customerPayment';

const rootReducer = combineReducers({
  dateRangeFilter: dateRangeFilterReducer,
  orderConfirmation: orderConfirmationReducer,
  supplierPayment: supplierPaymentReducer,
  customerPayment: customerPaymentReducer
});

export default rootReducer;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import APICaller from 'utils/APICaller';

const initialState = {
  supplierPaymentData: [],
  paymentCsvData: '',
  paidPaymentData: [],
  tableReload: false,
  lfbIdData: {}
};

export const addPaymentDetails = createAsyncThunk(
  '/supplierPayment/payment-request-details',
  async (formdata) => {
    const response = await APICaller({
      method: 'post',
      url: 'apifabric/orders/payment-request-details',
      data: formdata
    });
    return response;
  }
);
export const supplierPaymentDetails = createAsyncThunk(
  '/supplierPayment/payment-request',
  async ({ params }) => {
    const response = await APICaller({
      method: 'get',
      url: 'apifabric/orders/payment-request',
      offurl: '/dummy/table',
      params
    });
    return response;
  }
);
export const getPaidPaymentDataDetails = createAsyncThunk(
  '/supplierPayment/order-request-payment-details',
  async ({ params }) => {
    const response = await APICaller({
      method: 'get',
      url: 'apifabric/orders/order-request-payment-details',
      offurl: '/dummy/table',
      params
    });
    return response;
  }
);
export const rejectRequest = createAsyncThunk(
  'supplierPayment/update-payment-request',
  async ({ requestId, remarks }) => {
    const response = await APICaller({
      method: 'patch',
      url: `apifabric/orders/update-payment-request/${requestId}?isRejected=1`,
      data: { remarks }
    });
    return response;
  }
);
export const updatePayableDays = createAsyncThunk(
  'supplierPayment/update-order',
  async ({ orderId, params }) => {
    const response = await APICaller({
      method: 'patch',
      url: `apifabric/orders/update-order/${orderId}`,
      params
    });
    return response;
  }
);

export const supplierPaymentSlice = createSlice({
  name: 'supplierPayment',
  initialState,
  reducers: {
    tableReloader: (state) => {
      state.tableReload = !state.tableReload;
    },
    resetLfbidData: (state) => {
      state.lfbIdData = {};
    }
  },
  extraReducers: {
    [addPaymentDetails.fulfilled]: () => {
      message.success('Payment Captured Successful !');
    },
    [supplierPaymentDetails.fulfilled]: (state, { payload }) => {
      state.supplierPaymentData = payload?.supplierPaymentRequestList;
      state.paymentCsvData = payload?.csv;
    },
    [getPaidPaymentDataDetails.fulfilled]: (state, { payload }) => {
      state.paidPaymentData = payload;
    },
    [rejectRequest.fulfilled]: () => {
      message.success('Request Cancelled Successfully !');
    },
    [updatePayableDays.fulfilled]: () => {
      message.success('Payable days edited successfully');
    }
  }
});
export const { tableReloader, resetLfbidData } = supplierPaymentSlice.actions;

export default supplierPaymentSlice.reducer;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import APICaller from 'utils/APICaller';

const initialState = {
  customerPaymentData: [],
  paymentCsvData: '',
  tableReload: false
};

export const customerPaymentDetails = createAsyncThunk(
  '/customerPayment/payment-request',
  async ({ params }) => {
    const response = await APICaller({
      method: 'get',
      url: 'apifabric/orders/customer-payment-request',
      offurl: '/dummy/table',
      params
    });
    return response;
  }
);
export const captureCustomerPayment = createAsyncThunk(
  'order/captureCustomerPayment',
  async ({ fabricId, form }) => {
    const response = await APICaller({
      method: 'post',
      url: `apifabric/orders/customer-payment-request/${fabricId}`,
      data: form
    });
    return response;
  }
);

export const approveCustomerPaymentDetails = createAsyncThunk(
  '/orders/customer-payment-request-details',
  async ({ requestId, customerFormData }) => {
    const response = await APICaller({
      method: 'post',
      url: `apifabric/orders/customer-payment-request-details/${requestId}`,
      data: customerFormData
    });
    return response;
  }
);
export const approveAlignDeliveryRequest = createAsyncThunk(
  '/orders/customer-align-delivery-request',
  async ({ requestId, formDataItem }) => {
    const response = await APICaller({
      method: 'patch',
      url: `apifabric/orders/update-customer-align-delivery-request/${requestId}`,
      data: formDataItem
    });
    return response;
  }
);
export const rejectAlignDeliveryRequest = createAsyncThunk(
  'orders/customer-align-delivery-request',
  async ({ requestId, remarks }) => {
    const response = await APICaller({
      method: 'patch',
      url: `apifabric/orders/update-customer-align-delivery-request/${requestId}?isRejected=1`,
      data: { remarks }
    });
    return response;
  }
);
export const rejectCustomerRequest = createAsyncThunk(
  'orders/update-customer-payment-request',
  async ({ requestId, remarks }) => {
    const response = await APICaller({
      method: 'patch',
      url: `apifabric/orders/update-customer-payment-request/${requestId}?isRejected=1`,
      data: { remarks }
    });
    return response;
  }
);

export const customerPaymentSlice = createSlice({
  name: 'customerPayment',
  initialState,
  reducers: {
    tableReloader: (state) => {
      state.tableReload = !state.tableReload;
    }
  },
  extraReducers: {
    [customerPaymentDetails.fulfilled]: (state, { payload }) => {
      state.customerPaymentData = payload?.data;
      state.paymentCsvData = payload?.csv;
    },
    [captureCustomerPayment.fulfilled]: (state, { payload }) => {
      message.success('Payment captured successfully!');
    },
    [approveCustomerPaymentDetails.fulfilled]: () => {
      message.success('Payment approved successfully!');
    },
    [rejectCustomerRequest.fulfilled]: () => {
      message.success('Request Cancelled Successfully !');
    }
  }
});
export const { tableReloader } = customerPaymentSlice.actions;

export default customerPaymentSlice.reducer;
